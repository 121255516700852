@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    @font-face {
        font-family: IRANSans;
        font-style: normal;
        font-weight: bold;
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Bold.eot');
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
        url('assets/fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
        url('assets/fonts/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('assets/fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
    }
    @font-face {
        font-family: IRANSans;
        font-style: normal;
        font-weight: 500;
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Medium.eot');
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
        url('assets/fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
        url('assets/fonts/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('assets/fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
    }
    @font-face {
        font-family: IRANSans;
        font-style: normal;
        font-weight: 300;
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Light.eot');
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
        url('assets/fonts/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
        url('assets/fonts/woff/IRANSansWeb(FaNum)_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('assets/fonts/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
    }
    @font-face {
        font-family: IRANSans;
        font-style: normal;
        font-weight: 200;
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot');
        src: url('assets/fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
        url('assets/fonts/woff2/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
        url('assets/fonts/woff/IRANSansWeb(FaNum)_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('assets/fonts/ttf/IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');
    }
    @font-face {
        font-family: IRANSans;
        font-style: normal;
        font-weight: normal;
        src: url('assets/fonts/eot/IRANSansWeb(FaNum).eot');
        src: url('assets/fonts/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
        url('assets/fonts/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
        url('assets/fonts/woff/IRANSansWeb(FaNum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('assets/fonts/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
    }

}
@layer components {
    .filter-btn {
        @apply w-[70px] h-[70px] rounded-full flex justify-center items-center flex-col fixed bottom-[70px] left-[11px] z-30 text-white bg-custom-black3;
    }
    .more-yellow-btn{
        @apply flex justify-between items-center border border-custom-yellow2 bg-custom-black4 text-white rounded-[10px] px-[14px] h-[50px];
    }
    .more-red-btn{
        @apply flex justify-between items-center border border-custom-red2 bg-custom-black4 text-white rounded-[10px] px-[14px] h-[50px];
    }
    .custom-sort-label{
        @apply text-white text-base font-normal !important;
    }


}
.overflow-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.overflow-nowrap-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
}
.overflow-nowrap-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 3;
    -moz-box-orient: vertical;
}
.dir-ltr{
    direction: ltr;
}
.dir-rtl{
    direction: rtl;
}
body{
    background: #131415;
    direction: rtl;
}
.swiper-pagination-bullet{
    background: white !important;
}
.custom-slide img{
    box-shadow: 0px -45px 40px 0px #000000E5 inset;
    border-radius: 5px;
}
.custom-movie img{
    border-radius: 6px;
    display: block;
}
.custom-search-btn{
    height: 60px;
    text-align: center;
    border-radius: 15px;
    background: #E40914;
    font-size: 15px;
    font-weight: 500;
    color:white;
    position: relative;
    z-index: 11;
}
.custom-search-btn-bg{
    width: 95%;
    display: block;
    margin: auto;
    height: 56px;
    border-radius: 15px;
    background: #E40914;
    filter: blur(15px);
    margin-top: -52px;
    position: relative;
    z-index: 10;
}
.custom-movie .rate{
    background: url("assets/img/svg/rate.svg") no-repeat;
    background-size: cover;
    width: 28px;
    height: 28px;
    font-size: 8px;
    color: #1D2025;
    padding-right: 2px;
    padding-top: 10px;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 5px;
}
.custom-movie .delete{
    position: absolute;
    top: 0px;
    right: 0px;
}
.custom-delete-border-shadow{
    outline: 0 !important;
    box-shadow:unset !important;
}
/* انیمیشن باز شدن */
.modal-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.modal-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

/* انیمیشن بسته شدن */
.modal-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.modal-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.modal-exit {
    opacity: 1;
    transform: translateY(0);
}

.modal-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms;
}
.order-modal{
    position: relative;
    border-radius: 16px;
    overflow: hidden;
}
.order-modal:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(29,32,37,0.9);
    filter: blur(5px);
}
.custom-input-icon input{
    background: #363C46;
    border-radius:  5px !important;
    outline: 0 !important;
    box-shadow:unset !important;
    color:white;
    border-color: transparent;

}
.custom-input-icon input:focus{
    border-color:#FFB900 !important;
}
.custom-input-icon input::placeholder{
    color:#CCD6E1;
}
.custom-select select{
    background: #363C46;
    border-radius: 10px;
    color:white;
}
.custom-select select{
    background: #363C46;
    border-radius: 10px;
    background-image: url("assets/img/svg/arrow-down.svg");
    background-repeat: no-repeat;
    border-color:transparent !important;
}
.custom-switch   > div{
    /*background: #FFB900;*/
    border-color:transparent !important;
}
.custom-switch:focus > div{
    box-shadow: unset !important;
    border-color:transparent !important;
    outline: 0 !important;
}
.custom-select select:focus{
    border-color:#FFB900 !important;
    box-shadow: unset;
}
.range-slider input[type=range]{
    display: none;
}
.range-slider {
    /* CSS for the wrapper element */
    background: #363C46;
    height: 4px;
}
.range-slider[data-disabled] {
    /* CSS for disabled range slider element */
}
.range-slider .range-slider__range {
    /* CSS for range */
    background:#BC8B0B;
}
.range-slider .range-slider__range[data-active] {
    /* CSS for active (actively being dragged) range */
}
.range-slider .range-slider__thumb {
    /* CSS for thumbs */
    background:#FFB900;
    width: 14px;
    height: 14px;
}


.movie-header{
    position: relative;
    height: 276px;
    overflow: hidden;
}

.movie-header-bg {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 276px;
    filter: blur(30px);
}
.movie-rate{
    background: url("assets/img/svg/rate.svg");
    width: 34px;
    height: 34px;
    font-size: 8px;
    color: #1D2025;
    padding-top: 7px;
    text-align: center;
}
.custom-accordion-title{
    background: #1F2329;
    color:white;
    font-size: 17px;
    font-weight: 400;
    border-radius: 10px;
}
.custom-accordion-title:hover{
    background-color: #1F2329 !important; ;
    color:white;
}
.custom-accordion-title:focus{
    box-shadow: unset !important;
}
.header-title-shadow{
    background: linear-gradient(90.39deg, rgba(255, 185, 0, 0.30) -3.82% , rgba(255, 185, 0, 0.3) 99.62%);
    filter: blur(20px);
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    height: 20px;
}
/**:focus {*/
/*    outline: none;*/
/*    box-shadow: 0 0 0 2px #709fdc;*/
/*}*/